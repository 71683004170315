@import './_variables.scss';
@import './vendor/bootstrap-reboot.min.scss';
@import './vendor/bootstrap-grid.min.scss';

html {
  min-height: 100vh;
}

body {
  overscroll-behavior: contain;
}

.col-sm-6 {
  width: 50%;
}

ul {
  padding: 0;
  margin: 0;
}

.container-admin {
  background: white;
  padding: 30px;
}

.attribute-new {
  text-align: end;
}

.button_select {
  width: 20%;
  padding: 10px 0px;
  margin-bottom: 5px;
  border: none;
  border-radius: 10px;
}

.button_select_blue {
  background-color: blue;
  margin-right: 10px;
}

.button_select_red {
  background-color: #e51a1a;
}

.button_model {
  border-radius: 50%;
}

.button_select_orange {
  margin-right: 5px;
  background-color: $orange;
}

.button_show_models {
  border-radius: 10px;
  font-size: 13px;
  font-weight: 600;
  color: white;
  background: blue;
}

.button_delete_model {
  border-radius: 30%;
  font-weight: 700;
  margin: 3px;
  color: white;
  background-color: red;
}

// .carousel .control-next.control-arrow:before {
//   border-left: 38px solid #fff !important;
// }

// .carousel .control-arrow:before,
// .carousel.carousel-slider .control-arrow:before {
//   margin: 0 5px !important;
//   display: inline-block !important;
//   border-top: 38px solid transparent !important;
//   border-bottom: 38px solid transparent !important;
//   content: '' !important;
// }

// .carousel .control-prev.control-arrow:before {
//   border-right: 38px solid #fff !important;
// }

.file-input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
}

.carousel-root {
  // display: flex;
  width: 40%;
  justify-content: center;
  align-items: flex-end;
}

.carousel {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
}

.justify-center {
  justify-content: center;
  align-items: center;
  display: flex !important;
}

.header-new-logo {
  width: 100%;
  height: 75px;
}

// global
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
  background-color: $light;
  color: $primary;
  font-family: $font;
  font-size: 16px;
}

::-moz-focus-inner {
  border: 0;
}

a,
a:hover,
a:visited,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
  outline: 0 !important;
}

button,
input,
select,
textarea {
  outline: 0 !important;
  box-shadow: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

.img-fluid {
  max-width: 100%;
  width: auto !important;
  max-height: 600px;
}

.w-100 {
  width: 100% !important;
}

.button {
  outline: 0 !important;
  border: none;
  border-radius: 0px;
  background-color: $blue !important;
  padding: 12px;
  min-width: 120px;
  font-size: 16px;
  text-decoration: none !important;
  color: #fff !important;
  @include transition(all 0.25s);
  display: inline-block;
  text-align: center;
  border-radius: 7px;

  img {
    margin-top: -3px;
    margin-bottom: -3px;
    height: 25px;
    display: inline-block;
    vertical-align: middle;
    position: relative;
    top: -2px;
  }

  &:hover {
    box-shadow: 0 0 0 5px rgba(65, 117, 223, 0.4) !important;
  }

  &_dark {
    background-color: $dark !important;

    &:hover {
      box-shadow: 0 0 0 5px rgba(65, 77, 98, 0.4) !important;
    }
  }

  &_light {
    background-color: #fff !important;
    color: $blue !important;
    border: 1px solid $blue !important;
  }

  &_red {
    background-color: $red !important;

    &:hover {
      box-shadow: 0 0 0 5px rgba(229, 26, 26, 0.4) !important;
    }
  }

  &_orange {
    background-color: $orange !important;
  }

  &_green {
    background-color: $green !important;

    &:hover {
      box-shadow: 0 0 0 5px rgba(89, 195, 106, 0.4) !important;
    }
  }

  &.fixed {
    position: fixed;
    bottom: 20px;
    width: 30% !important;
    right: 20px;
  }
}

.loader-holder {
  position: fixed;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  z-index: 99999;
  background: rgba(255, 255, 255, 0.2);
}

.suggestion {
  padding: 10px 20px;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.suggestion:hover {
  background-color: #e8e8e8;
}

.link-disabled {
  pointer-events: none;
  opacity: 0.3;
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

.button_w_icon {
  display: inline-block;
  background-color: $blue;
  color: #fff;
  height: 45px;
  position: relative;
  padding: 10px 20px 10px 60px;
  border-radius: 30px;
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff !important;
  @include transition(all 0.2s);

  span {
    content: '';
    position: absolute;
    // z-index: 2;
    left: -1px;
    top: 0;
    display: inline-block;
    height: 45px;
    width: 45px;
    padding-top: 7px;
    border: 2px solid #fff;
    border-radius: 50%;
    text-align: center;

    img {
      height: 20px;
      display: inline-block;
    }
  }

  &_green {
    background-color: $green;
  }

  &:hover {
    background-color: $dark;
  }
}

.jumbotron {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 10px;

  & + .jumbotron {
    margin-top: 30px;
  }

  h2 {
    font-size: 30px;
  }

  h3 {
    margin-bottom: 15px;
  }

  .button_w_icon,
  .button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

.alert {
  font-size: 15px;
  border-radius: 2px;
  margin-bottom: 20px;
  padding: 15px;
  @include text-wrap;

  a {
    text-decoration: underline;
  }

  h6,
  h5,
  h4,
  h3,
  h2 {
    font-size: 20px;
    margin: 0;
  }

  p {
    font-size: 18px;
    margin: 0;
  }
}

.badge {
  background-color: $dark;
  display: inline-block;
  vertical-align: middle;
  min-width: 30px;
  height: 25px;
  line-height: 25px;
  border-radius: 5px;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
  padding: 0 8px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  // inset: 25% !important;
  height: 65%;
}

.navbar-homepage {
  text-align: end;
}

.text {
  &-center {
    text-align: center;
  }

  &-right {
    text-align: right;
  }

  &-left {
    text-align: left;
  }
}

.active_category_objava {
  font-weight: bold;
  color: $blue;
}

@media only screen and (min-width: 768px) {
  .text {
    &-sm-center {
      text-align: center;
    }

    &-sm-right {
      text-align: right;
    }

    &-sm-left {
      text-align: left;
    }
  }

  .detail .detail_price .credit:hover::before {
    content: 'Iznos rate je informativnog karaktera';
    position: absolute;
    top: -35px;
    width: max-content;
    background: #4175dfdc;
    font-size: 13px;
    left: -10px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .detail .followers .follower:hover::before {
    content: 'Broj korisnika koji prate ovaj oglas';
    width: 80%;
    font-weight: 700;
    position: absolute;
    top: -15px;
  }
}

.svg_feature:hover::before {
  content: 'Izdvoji oglas';
  position: absolute;
  top: -35px;
  line-height: 20px;
  width: max-content;
  background: #4175dfdc;
  font-size: 13px;
  right: 0px;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px;
  text-transform: uppercase;
}

.svg_edit:hover::before {
  content: 'Izmijeni oglas';
  position: absolute;
  top: -35px;
  line-height: 20px;
  width: max-content;
  background: #d16015dc;
  font-size: 13px;
  right: 0px;
  color: white;
  font-weight: 700;
  border-radius: 5px;
  padding: 5px;
  text-transform: uppercase;
}

.section {
  padding-top: 40px;
  padding-bottom: 70px;
  position: relative;
  overflow: hidden;

  &_white {
    background-color: #fff;
  }
}

.row {
  &_10 {
    margin-left: -5px;
    margin-right: -5px;

    & > div,
    & > article {
      padding-left: 5px;
      padding-right: 5px;
    }
  }

  &_20 {
    margin-left: -10px;
    margin-right: -10px;

    & > div,
    & > article {
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}

.separator {
  width: 100%;
  height: 1px;
  background-color: #e0e4ee;
  position: relative;
  margin: 0px 0 15px 0;

  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    // background-color: $red;
    width: 70px;
    height: 100%;
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  margin-bottom: 20px;
  @include text-wrap;

  .badge {
    margin-left: 5px;
    font-size: 20px;
    line-height: 30px;
    height: 30px;
  }
}

.default_dropdown {
  display: none;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
  background-color: #fff;
  border: 1px solid #eee;
  padding: 5px;
  position: absolute;
  z-index: 100;
  top: 100%;
  left: 0;
  min-width: 200px;

  &.right {
    left: auto;
    right: 0;
  }

  &.visible {
    display: inline-block;
    font-weight: 900;
  }

  a {
    display: block;
    padding: 5px 10px;
    @include transition(all 0.2s);

    &:hover {
      // background-color: $light;
    }
  }

  &_more {
    margin-top: 5px;
    // padding: 5px 10px !important;
    background-color: $gray !important;
    color: $blue !important;
    font-weight: bold;
    text-align: center;
    text-transform: uppercase;
    font-size: 14px;

    &:hover {
      background-color: $blue !important;
      color: #fff !important;
    }
  }
}

.notifications_dropdown {
  min-width: 300px;
  max-height: 370px;
  overflow-y: auto;

  a {
    position: relative;
    // padding: 10px 10px 10px 55px;
    padding: 10px;
    border-bottom: 1px solid $gray !important;

    &:last-child {
      border: none;
    }

    img {
      height: 35px;
      width: 35px;
      border-radius: 50%;
      position: absolute;
      left: 10px;
      top: 12px;
    }

    span {
      min-height: 40px;
      display: inline-block;
      line-height: 1.4;
      font-size: 14.5px;
    }

    time {
      display: block;
      margin-top: 5px;
      font-size: 12.5px;
      font-weight: 500;
      color: #999;
    }
  }
}

.app_wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.default_pagination {
  position: relative;
  background-color: #fff;
  padding: 10px;
  @include boxShadow;

  a {
    @include transition(all 0.2s);

    &:hover {
      background-color: $gray;
    }
  }

  &_numbers {
    ul {
      display: inline-block;
      padding-left: 0;
      margin-bottom: 0;
      list-style-type: none;

      li + li {
        margin-left: 5px;
      }

      li {
        display: inline-block;

        a {
          background-color: $light;
          display: inline-block;
          height: 40px;
          width: 40px;
          line-height: 40px;
          text-align: center;
          font-weight: 500;
        }

        &.active {
          a {
            background-color: $gray;
          }
        }
      }
    }
  }

  &_arrows {
    padding-left: 5px;
    margin-left: auto;

    a + a {
      margin-left: 5px;
    }

    a {
      background-color: $light;
      display: inline-block;
      height: 40px;
      width: 40px;
      text-align: center;

      img {
        margin-top: 13px;
        height: 14px;
      }
    }
  }
}

.media {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  &-media-body {
    -webkit-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    min-width: 10%;
  }
}

.comments {
  padding-bottom: 20px;

  &_item {
    padding: 20px 15px 20px 20px;
    background-color: #fff;

    &:nth-child(even) {
      background-color: $gray;
    }

    .comments_item {
      border-top: 1px dashed #ccc;
      margin-top: 20px;
      padding: 20px 0 0;
      background-color: transparent;
    }

    figure {
      margin-right: 15px;
      margin-bottom: 15px;

      img {
        height: 70px;
        width: 70px;
        border-radius: 50%;
      }
    }

    h5 {
      font-size: 17px;
      font-weight: bold;
      margin-bottom: 5px;
    }

    time {
      font-size: 14px;
      display: block;
      margin-bottom: 10px;
      color: #666;
    }

    p {
      font-size: 16px;
      margin-bottom: 5px;
    }

    &_reply {
      text-align: right;
      margin-bottom: 10px;

      button {
        background-color: transparent;
        border: none;
        padding: 0;
        font-weight: 600;
        color: $blue;

        img {
          height: 22px;
          margin-right: 5px;
        }
      }
    }
  }
}

// form_default
.form_default {
  &_label {
    display: block;
    margin-bottom: 5px;
    font-weight: 400;
    font-size: 16px;
  }

  &_input {
    display: block;
    margin-bottom: 20px;
    position: relative;

    input {
      height: 50px;
      line-height: 50px;
      display: block;
      width: 100%;
      border: none;
      background-color: #fff;
      border-bottom: 1px solid #bbb;
      @include transition(all 0.2s);

      &:focus {
        border-bottom-color: $blue;
      }

      &:disabled {
        background-color: #eee;
        border: none;
      }
    }
  }

  &_select {
    display: block;
    margin-bottom: 20px;
    position: relative;

    select {
      height: 50px;
      display: block;
      width: 100%;
      border: none;
      background-color: #fff;
      border-bottom: 1px solid #bbb;
      @include transition(all 0.2s);

      &:focus {
        border-bottom-color: $blue;
      }

      &:disabled {
        background-color: #eee;
        border: none;
      }
    }
  }

  &_w_icon {
    input,
    select {
      padding-left: 45px;

      &:focus + img {
        opacity: 1;
      }
    }

    img {
      position: absolute;
      left: 0;
      bottom: 15px;
      max-height: 23px;
      max-width: 25px;
      opacity: 0.5;
      @include transition(all 0.2s);
    }
  }

  &_w_success {
    input,
    select {
      padding-right: 30px;
      border-color: $green;
    }

    &:after,
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
    }

    &:before {
      right: 0;
      bottom: 15px;
      z-index: 2;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background-color: $green;
    }

    &:after {
      right: 7px;
      bottom: 21px;
      z-index: 3;
      width: 6px;
      height: 10px;
      background-color: transparent;
      border-right: 2px solid #fff;
      border-bottom: 2px solid #fff;
    }

    &:after {
      @include transform(rotate(45deg));
    }
  }

  &_w_error {
    input,
    select {
      padding-right: 30px;
      border-color: $red;
    }

    &:after,
    &:before {
      content: '';
      display: inline-block;
      position: absolute;
      z-index: 2;
      right: 5px;
      bottom: 15px;
      height: 15px;
      width: 2px;
      background-color: $red;
    }

    &:after {
      @include transform(rotate(-45deg));
    }

    &:before {
      @include transform(rotate(45deg));
    }
  }

  &_error {
    color: $red;
    display: block;
    margin-top: -10px;
    margin-bottom: 15px;
    font-size: 14px;
  }

  &_check {
    display: inline-block;
    position: relative;
    padding-left: 32px;
    margin-bottom: 20px;

    input {
      position: absolute;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    span {
      display: inline-block;
      border: 2px solid #ccc;
      width: 20px;
      height: 20px;
      background-color: #fff;
      position: absolute;
      left: 0;
      bottom: 2px;
      @include transition(all 0.2s);

      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 10px;
        left: 0;
        right: 0;
        top: 2px;
        bottom: 0;
        margin: 0 auto;
        border-right: 2px solid $blue;
        border-bottom: 2px solid $blue;
        @include transform(rotate(45deg));
        opacity: 0;
        @include transition(all 0.2s);
      }
    }

    &.radio {
      span {
        border-radius: 50%;
      }
    }

    input:checked + span {
      border-color: $blue;

      &:after {
        opacity: 1;
      }
    }
  }

  &_option {
    display: inline-block;
    margin-bottom: 20px;
    cursor: pointer;

    input {
      position: absolute;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    span {
      display: block;
      position: relative;
      padding: 11px 50px 11px 15px;
      background-color: #fff;
      border: 1px solid #d3d3d3;
      @include transition(all 0.2s);

      &:before {
        content: '';
        position: absolute;
        z-index: 4;
        width: 6px;
        height: 10px;
        right: 15px;
        top: 14px;
        border: 2px solid #ccc;
        width: 20px;
        height: 20px;
        @include transition(all 0.2s);
      }

      &:after {
        content: '';
        position: absolute;
        z-index: 5;
        width: 7px;
        height: 11px;
        right: 21px;
        top: 17px;
        border-right: 2.5px solid $blue;
        border-bottom: 2.5px solid $blue;
        @include transform(rotate(45deg));
        opacity: 0;
        @include transition(all 0.2s);
      }
    }

    input:checked + span {
      color: $blue;
      background-color: $light;

      &:after {
        opacity: 1;
      }

      &:before {
        border-color: $blue;
      }
    }

    input:disabled + span {
      background-color: #eee;
      color: #999;

      &:before {
        background-color: #eee;
      }
    }
  }

  &_link {
    display: inline-block;
    color: $blue !important;
    text-decoration: underline !important;
    margin-bottom: 20px;
  }

  &_steps {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border-radius: 30px;
    border: 1px solid #ccc;
    margin-bottom: 15px;

    span {
      display: inline-block;
      align-self: center;
      color: #888;
      font-weight: 500;
      padding-left: 5px;
    }

    ul {
      padding-left: 0;
      list-style-type: none;
      display: inline-block;
      margin-bottom: 0;

      li + li {
        margin-left: 7px;
      }

      li {
        display: inline-block;
        width: 35px;
        height: 35px;
        line-height: 35px;
        border-radius: 50%;
        background-color: $gray;
        text-align: center;
        font-weight: bold;
        font-size: 20px;

        &.active {
          background-color: $blue;
          color: #fff;
        }
      }
    }
  }

  &_gender {
    margin-bottom: 20px;
    margin-right: 15px;

    input {
      position: absolute;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    span {
      display: inline-block;
      padding: 10px;
      border-radius: 30px;
      border: 1px solid #ccc;
      min-width: 150px;
      cursor: pointer;
      @include transition(all 0.2s);

      img {
        margin-right: 5px;
        height: 30px;
        vertical-align: middle;
      }
    }

    input:checked + span {
      border-color: $blue;
      color: $blue;
    }
  }

  &_image {
    display: flex;
    justify-content: space-between;
    background-color: $blue;
    color: #fff;
    padding: 30px;
    margin-bottom: 30px;
    border-radius: 5px;
    position: relative;

    h3 {
      font-size: 24px;
      font-weight: bold;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    button {
      border: none;
      vertical-align: middle;
      background-color: transparent;
      inset: 0;
      color: #fff;
      align-self: flex-end;
      @include transition(all 0.2s);

      @media screen and (min-width: 416px) {
        text-align: left;
        padding-left: 2rem;
      }

      @media screen and (max-width: 415px) {
        display: flex;
        align-items: center;
        padding-left: 2rem;
      }

      span {
        display: inline-block;
        vertical-align: middle;
      }

      &:after {
        margin-left: 20px;
        vertical-align: middle;
        display: inline-block;
        content: '+';
        width: 35px;
        height: 35px;
        font-size: 26px;
        line-height: 33px;
        text-align: center;
        border-radius: 50%;
        background-color: #fff;
        color: $blue;
      }
    }

    .image-item {
      max-width: 50%;

      .image-item__btn-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        width: 100%;
        text-align: right;

        .remove-image {
          background: red;
          border-radius: 1rem;
          aspect-ratio: 1;
          padding: 0 5px;

          &::after {
            content: none;
          }
        }
      }

      @media screen and (max-width: 415px) {
        margin: 1.5rem 0;
      }
    }

    .image-item + button {
      @media screen and (max-width: 415px) {
        align-items: flex-end;
        justify-content: center;
        padding: 1rem 0;
        margin-inline: auto;
      }
    }

    img {
      width: 100%;
    }
  }

  &_congratulation {
    padding-top: 0;
    text-align: center;

    h2 {
      font-size: 32px;
      font-weight: bold;
      margin-top: 20px;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 24px;
      font-weight: 400;
      margin-bottom: 20px;
    }

    a {
      display: inline-block;

      img {
        height: 80px;
      }
    }
  }
}

// search_categories
.search_categories {
  /*background-color: $light;*/
  padding: 10px 15px;
  max-height: 350px;
  overflow-y: auto;
  margin-bottom: 20px;

  ul {
    margin-bottom: 0;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    list-style-type: none;

    li {
      position: relative;
      display: inline-block;
      padding: 5px;
      margin-right: 30px;
      width: 90%;
      cursor: pointer;
      @include transition(all 0.2s);

      &:hover {
        background-color: $gray;
      }

      b {
        color: $blue;
      }

      &:last-child {
        margin-right: 0;

        &:after {
          display: none;
        }
      }

      // &:after {
      //   content: '';
      //   position: absolute;
      //   right: -18px;
      //   top: 14px;
      //   width: 8px;
      //   height: 8px;
      //   border-right: 2px solid #999;
      //   border-bottom: 2px solid #999;
      //   opacity: 0.7;
      //   @include transform(rotate(-45deg));
      // }
    }
  }
}

// text_only
.text_only {
  padding-bottom: 15px;
  @include text-wrap;

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-weight: bold;
    margin-bottom: 15px;
  }

  h1 {
    font-size: 30px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 22px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 18px;
  }

  h6 {
    font-size: 16px;
  }

  p,
  ul,
  ol {
    font-size: 15px;
    margin-bottom: 20px;

    a {
      color: $blue;
    }
  }

  ul,
  ol {
    padding-left: 20px;

    li {
      margin-bottom: 5px;
    }
  }

  img {
    max-width: 100% !important;
    height: auto !important;
  }
}

// article_type
.article_type {
  margin-bottom: 30px;

  & > a {
    display: block;
    background-color: #fff;
    padding: 30px;
    @include transition(all 0.2s);

    &:hover {
      background-color: $gray;

      button {
        background-color: $dark;
      }
    }

    div {
      position: relative;
      padding-right: 60px;
      min-height: 50px;
    }
  }

  button {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 45px;
    height: 45px;
    line-height: 43px;
    border-radius: 50%;
    border: none;
    padding: 0;
    font-size: 40px;
    font-weight: 300;
    background-color: $dark;
    color: #fff;
    @include transition(all 0.2s);

    &.red {
      background-color: $red;
    }

    &.blue {
      background-color: $blue;
    }

    &.green {
      background-color: $green;
    }

    &.orange {
      background-color: $orange;
    }
  }

  p {
    margin-bottom: 0;
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    border-bottom: 1px dashed #ccc;
    padding-bottom: 15px;
    margin-bottom: 15px;
  }
}

// collapse
.collapse_default {
  // margin-bottom: 15px;

  &_head {
    background-color: $gray;
    border: 2px solid #fff;
    padding: 15px 45px 15px 15px;
    display: block;
    width: 100%;
    text-align: left;
    font-weight: 600;
    position: relative;
    @include transition(all 0.2s);

    &:hover {
      color: $blue;
    }

    &:after {
      @include transition(all 0.2s);
      content: '';
      position: absolute;
      z-index: 2;
      top: 20px;
      right: 15px;
      width: 12px;
      height: 12px;
      border-right: 2px solid #999;
      border-bottom: 2px solid #999;
      @include transform(rotate(45deg));
    }
  }

  &_body {
    background-color: #fff;
    position: relative;
    @include transition(all 0.25s ease);
    max-height: 0px;
    overflow-y: scroll;
  }

  &_wrap {
    padding: 10px;
  }

  &.open {
    .collapse_default_head {
      color: $blue;

      &:after {
        color: $blue;
        top: 25px;
        @include transform(rotate(-135deg));
        border-color: $blue;
      }
    }
  }
}

// animations
@keyframes fadeToTop {
  from {
    opacity: 0;
    transform: translate(0, 50px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

@keyframes fadeToBottom {
  from {
    opacity: 0;
    transform: translate(0, -50px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0px);
  }
}

@keyframes fadeToCenter {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes fadeToRight {
  from {
    opacity: 0;
    transform: translate(-50px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

@keyframes fadeToLeft {
  from {
    opacity: 0;
    transform: translate(50px, 0);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

// responsive
@include md {
  body {
    padding-bottom: 80px;
  }

  .app_wrapper {
    min-height: auto;
  }
}

@include sm {
  body {
    padding-bottom: 60px;
  }

  .section {
    padding-top: 15px;
    padding-bottom: 50px;
  }

  .jumbotron {
    padding: 30px 15px;

    h2 {
      font-size: 22px;
    }

    .button,
    .button_w_icon {
      width: 100%;
    }
  }

  .title {
    font-size: 20px;

    .badge {
      font-size: 18px;
      line-height: 25px;
      height: 25px;
    }
  }

  .mt-30 {
    margin-top: 30px;
  }

  .g-15 {
    gap: 15px;
  }

  .ReactModal__Content.ReactModal__Content--after-open {
    // inset: 10% !important;
    margin-top: 20%;
  }

  .form_default {
    &_w_icon {
      input,
      select {
        padding-left: 37px;
      }
    }

    &_steps {
      span {
        font-size: 14px;
      }

      ul {
        li + li {
          margin-left: 5px;
        }
      }
    }

    &_gender {
      margin-right: 10px;

      span {
        padding-right: 15px;
        min-width: inherit;

        img {
          margin-right: 2px;
          height: 27px;
        }
      }
    }

    &_image {
      padding: 30px;

      h3 {
        font-size: 20px;
        margin-bottom: 10px;
      }

      button {
        padding-left: 15px;

        &:after {
          margin-left: 15px;
        }
      }

      @media screen and (max-width: 415px) {
        .no-mobile {
          display: none;
        }
      }
    }

    &_congratulation {
      padding-top: 0;
    }
  }

  .article_type {
    margin-bottom: 15px;

    & > a {
      padding: 15px;
    }
  }

  .search_categories {
    ul {
      li {
        margin-right: 20px;

        &:last-child {
          margin-right: 0;
        }
      }

      li {
        font-size: 14px;

        &:after {
          top: 11px;
          right: -12px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }

  .comments {
    &_item {
      padding-left: 15px;
      padding-top: 15px;

      time {
        margin-bottom: 5px;
      }

      figure {
        margin-right: 10px;
        margin-bottom: 10px;

        img {
          height: 45px;
          width: 45px;
        }
      }

      p {
        font-size: 14.5px;
      }

      &_reply {
        text-align: left;
      }
    }
  }
}

.row.row_20.posts_5:empty::before {
  content: 'Trenutno nema oglasa u ovoj kategoriji';
  width: 100%;
  text-align: center;
}

.container {
  max-width: 1200px;
}

.Toastify__toast-container {
  top: 40% !important;
  border-radius: 50%;
}

.ReactModal__Content.ReactModal__Content--after-open textarea {
  background-color: #f5f6f8;
  display: block;
  border-radius: 5px;
  border: none;
  margin-bottom: 20px;
  padding: 15px;
  resize: none;
  transition: all 0.2s;
  width: 100%;
  height: 300px;
}

// .ReactModal__Content.ReactModal__Content--after-open div {
//   background-color: black;
// }

.upload__image-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  gap: 10px;
}

.image-item {
  position: relative;
  border: 1px solid #fff;
  border-radius: 5px;

  .post_hide {
    cursor: pointer;
  }
}

.store-info {
  background-color: #4175dfaf;
  border-radius: 15px;
  text-align: center;
  padding: 10px 0px;
  color: white;
}

.store-heading {
  font-weight: 700;
}

.no-wrap {
  flex-wrap: nowrap;
}

.help-icon {
  position: fixed;
  right: 20px;
  bottom: 20px;
  background-color: transparent;
  border: 0;
  z-index: 9;

  svg {
    background-color: #fff;
    border-radius: 50%;
    border: 1px solid #fff;

    &:hover {
      opacity: 0.5;
    }
  }
}

// .button-red {
//   background-color: #e51a1a;
// }
.errors {
  color: #e51a1a;
  list-style-type: none;
}

button.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  border: 0;
}

button.button_w_icon.header_add {
  border: 0;
}

.control-arrow {
  padding: 2% !important;
}

.control-prev,
.control-next {
  background-color: rgba(255, 0, 0, 0) !important;
}

.carousel .control-arrow,
.carousel.carousel-slider .control-arrow {
  display: inline-block !important;
}

.carousel-status {
  font-size: 20px !important;
  font-weight: 600 !important;
}

.carousel .control-prev.control-arrow:before {
  border-right: 18px solid #fff;
}

.carousel .control-arrow:before,
.carousel.carousel-slider .control-arrow:before {
  border-top: 18px solid transparent;
  border-bottom: 18px solid transparent;
}

.carousel .control-next.control-arrow:before {
  border-left: 18px solid #fff;
}

.thumbs-wrapper.axis-vertical button {
  display: none !important;
}

.carousel .thumb {
  border: 1px solid #333;
  width: auto !important;
}

.carousel .thumb img {
  height: 70px !important;
}

.carousel .thumb.selected,
.carousel .thumb:hover {
  border: 2px solid #ccc;
}

// .slider.animated {
//   max-height: 372px;
// }

.ReactModal__Content.ReactModal__Content--after-open .slider.animated {
  max-height: 80%;
}

// .ReactModal__Content.ReactModal__Content--after-open {
//   inset: unset !important;
//   height: auto !important;
// }

.close_modal_button {
  position: fixed;
  top: 2%;
  right: 3%;
  color: white;
  font-weight: 800;
  background-color: $red;
  border: none;
  border-radius: 5px;
  width: 80px;
  padding: 5px;
}

.image_detail_desktop img {
  width: auto;
  max-width: 50%;
  height: 300px;
  margin-top: 20px;
  margin-bottom: 5px;
  border: 2px solid #aaa;
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}

.detail_price_info_label {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
}

.detail_impressions_info {
  height: 30px;
  width: 30px;
  margin: 0px 5px;
}

.user_verified_detail {
  font-size: 20px;
  color: $green;
  font-weight: bold;
}

.ReactModal__Overlay--after-open {
  z-index: 1000;
}

.has-children .subsubsub {
  height: 50vh;
  overflow-y: scroll;
}

@media screen and (max-width: 480px) {
  .has-children .subsubsub {
    height: 50vh;
    overflow: scroll;
  }

  // .ReactModal__Content.ReactModal__Content--after-open {
  //   inset: unset !important;
  //   width: auto !important;
  //   margin: 60% 10px !important;
  //   height: auto !important;
  //   position: unset !important;
  // }
  .close_modal_button {
    top: 120px;
  }

  .detail_impressions_info {
    height: 15px;
    width: 15px;
    margin: 0px 3px;
  }

  .detail_impressions_info > strong {
    font-size: 13px;
  }

  .detail_image_wrapper {
    position: relative;
  }

  .detail_boxes {
    position: absolute;
    width: 100%;
    top: 7px;
    padding: 3px;
    align-items: center;
    display: flex;
    justify-content: space-between;
    z-index: 1;
  }

  .btn-close-modal {
    position: fixed;
    top: 10px;
    right: 5px;
    color: white;
    font-weight: 700;
    border: none;
    border-radius: 5px;
    padding: 15px 15px;
    background-color: #e51a1a;
    font-weight: 900;

    img {
      width: 30px;
    }
  }

  .category_aside .btn-close-modal {
    z-index: 2;
    background-color: #e51a1a;
    color: #ffffff;
    font-weight: 900;
  }

  .ReactModal__Content.ReactModal__Content--after-open textarea {
    height: 100px;
  }

  .control-dots {
    display: none;
  }

  .navbar-homepage {
    text-align: center;
  }

  .control-arrow.control-next:before,
  .control-arrow.control-prev:before {
    display: none !important;
  }

  .detail .detail_price .credit:hover::before {
    content: 'Iznos rate je informativnog karaktera';
    position: absolute;
    top: -150px;
    left: 10px;
    width: max-content;
    background: #4175dfaf;
    font-size: 13px;
    border-radius: 5px;
    padding: 5px 10px;
  }

  .detail .detail_price .row div > div {
    flex-direction: column;
    gap: 10px;
    font-size: 0.76rem;
  }

  .detail .followers:hover::before {
    content: 'Broj korisnika koji prate ovaj oglas';
    width: 80%;
    font-weight: 700;
    position: absolute;
    left: -25px;
  }

  .carousel-root {
    // display: flex;
    width: 100% !important;
  }

  .control-arrow {
    display: none;
  }

  .slider.animated {
    max-height: 350px;
  }
}

@media screen and (max-width: 580px) {
  .detail_slider {
  }

  .detail_title {
    padding-top: 10px;
  }

  .detail_title h1 {
    text-transform: uppercase;
    font-size: 18px !important;
  }

  .thumbs-wrapper.axis-vertical {
    // display: none;
  }

  .detail .img {
    padding: 0;
    margin: 0;
    max-height: 260px;
    overflow: hidden;
  }

  .header {
    padding: 0px;
    padding-top: 30px;
    top: -30px;
  }
}

.w-auto {
  width: auto !important;
}

.pagination-nav {
  @extend .w-auto, .d-flex, .align-items-center, .px-2;

  &[disabled] {
    pointer-events: none;
    opacity: 0.3;
  }

  img {
    margin-top: 0 !important;
    margin: 0 0.5rem;
  }
}

.pik {
  & > div {
    border: 3px solid #e51a1a;
    border-radius: 0.5rem;
    padding: 0.5rem;
    min-height: 18rem;
    position: relative;

    &:hover {
      &::after {
        content: 'Preuzmi oglas sa pika';
        position: absolute;
        display: flex;
        padding: 1rem;
        text-align: center;
        justify-content: center;
        align-items: center;
        inset: 0;
        color: #fff;
        font-weight: 900;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        inset: 0;
        background-color: #e51a1a;
        opacity: 0.8;
        z-index: 1;
      }
    }

    &[disabled]:hover {
      &::after {
        content: 'Oglas preuzet sa pika';
        position: absolute;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        text-align: center;
        inset: 0;
        color: #fff;
        font-weight: 900;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        inset: 0;
        background-color: #6ac97a;
        opacity: 0.8;
        z-index: 1;
      }
    }

    &[download]:hover {
      &::after {
        content: 'Oglas na listi za preuzimanje';
        position: absolute;
        display: flex;
        justify-content: center;
        padding: 1rem;
        align-items: center;
        text-align: center;
        inset: 0;
        color: #fff;
        font-weight: 900;
        z-index: 2;
      }

      &::before {
        content: '';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        inset: 0;
        background-color: #4175df;
        opacity: 0.8;
        z-index: 1;
      }
    }
  }

  & > [disabled] {
    border-color: #6ac97a;
  }

  & > [download] {
    border-color: #4175df;
  }
}

.form-control {
  width: 100%;
  border-radius: 2px;
  border: none;
  padding: 0.5rem;
  box-shadow: 0px 0px 0px 2px #eee !important;
}

.sale_inqury {
  background-color: #e51a1a !important;
}

.close-third {
  position: absolute;
  right: 0.5rem;
  top: -0.25rem;
}

[name='articleType'] {
  text-transform: capitalize;
  padding: 0 11px;
}

.share {
  @media screen and (min-width: 768px) {
    display: flex;
    justify-content: flex-end;

    & .detail_slider_circle {
      margin-right: 0;
    }

    & .share-icons-holder {
      gap: 10px;
      display: flex;
      align-items: start;
      padding: 2px 10px;
    }
  }
}

.s0 {
  fill: #665cac;
}

.s1 {
  fill: #523494;
}

.s2 {
  fill: #ffffff;
}

.s3 {
  fill: #e5e5e5;
}

.s4 {
  fill: url(#g1);
}

.s5 {
  fill: #ffffff;
}

.native-icons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

img.close_modal {
  position: absolute;
  right: 0;
  top: -0.5rem;
}

.carousel .thumbs-wrapper {
  margin: 20px 0;
  overflow-x: scroll;

  /* width */
  &::-webkit-scrollbar {
    height: 5px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: #b6cdfa;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #4175df;
    border-radius: 1rem;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}

.text-underline {
  text-decoration: underline;
}

.reduced-price {
  width: 60px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.nagrada-roditelj {
  width: 40%;
  margin: auto;
}

.nagrada {
  display: flex;
  margin: 1rem 0;
  font-size: 1.3rem;
  font-weight: 900;

  a {
    display: flex;
    width: 100%;
    align-items: center;
    flex-wrap: wrap;
    text-align: left;

    .draw {
      font-size: 0.9rem;
      font-style: italic;
      color: #e51a1a;
    }

    img {
      max-width: 100px;
      margin: 0 1rem;
      margin-left: auto;
    }
  }
}

.nagradna-igra-izvlacenje {
  div {
    display: flex;
    justify-content: center;
    align-items: center;

    img {
      width: 150px;
      margin: 0 1rem;
    }
  }
}

// iframe {
//   display: none;
// }

.carousel .slider-wrapper.axis-horizontal .slider {
  max-height: 300px;

  @media screen and (max-width: 700px) {
    max-height: 150px;
  }
}

.btn-search {
  padding: 0.5rem;
  margin-top: 0.5rem;
}

.pagination-wrapper {
  display: flex;
  padding: 0.5rem 0;
  background: #ffffff;
  gap: 0.5rem;

  .page-item {
    background-color: #f5f6f8;
    display: inline-block;
    width: 40px;
    line-height: 40px;
    text-align: center;
    font-weight: 500;
    border: none;

    &.active {
      color: #ffffff;
      background-color: #4175df;
    }
  }
}
