.fb_login_btn {
  width: 100%;
}

.fb_login_btn button {
  width: 100%;

  padding: 14px 0px;

  font-size: 13px;

  border-radius: 8px !important;
}
