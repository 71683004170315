.apps_holder {
  position: fixed;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  background-color: rgba(0, 0, 0, 0.6);

  z-index: 10000;
}

.apps_inner {
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  width: 90%;

  padding: 20px;

  background-color: #fff;

  border-radius: 10px;
}

.app_que {
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;

  font-size: 18px;
  color: #000;
  font-weight: 500;
  text-align: center;
}

.apps_btn_holder {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.apps_btns {
  display: inline-block;

  width: 130px;

  margin: 0px 5px;
  padding: 8px 5px;

  background-color: transparent;

  font-size: 16px;
  color: #fff;
  font-weight: 700;

  border-radius: 8px;

  cursor: pointer;

  img {
    width: 100%;
  }
}

.close_apps {
  position: absolute;
  top: 5px;
  right: 5px;

  width: 30px;
  height: 30px;

  padding: 0px 5px;

  font-size: 20px;

  cursor: pointer;
}

@media (max-width: 360px) {
  .apps_btns {
    width: 100px;
  }
}
