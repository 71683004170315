// variables
$font: 'Bai Jamjuree', sans-serif;
$primary: #222222;
$dark: #414c61;
$blue: #4175df;
$red: #e51a1a;
$orange: #e99f09;
$green: #6ac97a;
$light: #f5f6f8;
$gray: #edf0f6;

// mixins
@mixin transition($time) {
  -webkit-transition: $time;
  -moz-transition: $time;
  -ms-transition: $time;
  transition: $time;
}
@mixin transform($property) {
  -webkit-transform: $property;
  -moz-transform: $property;
  -ms-transform: $property;
  transform: $property;
}
@mixin column($property) {
  -ms-flex: 0 0 $property;
  flex: 0 0 $property;
  max-width: $property;
}
@mixin text-wrap {
  overflow-wrap: break-word;
  word-wrap: break-word;
}
@mixin ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
@mixin boxShadow {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@mixin lg() {
  @media only screen and (max-width: 1199.98px) {
    @content;
  }
}
@mixin md() {
  @media only screen and (max-width: 991.98px) {
    @content;
  }
}
@mixin sm() {
  @media only screen and (max-width: 767.98px) {
    @content;
  }
}
@mixin xs() {
  @media only screen and (max-width: 575.98px) {
    @content;
  }
}
