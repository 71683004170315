@import './_variables.scss';

.notifications {
  &_item {
    position: relative;
    background-color: #fff;
    padding: 7px 120px 7px 7px;
    margin-bottom: 10px;
    border-radius: 5px;
    border: 4px solid #fff;
    @include boxShadow;

    &.active {
      background-color: $light;

      .viewed {
        background-color: $blue !important;
      }

      time,
      p {
        color: #222;
        font-weight: 900;
      }
    }

    &_options {
      position: absolute;
      width: 100px;
      text-align: right;
      top: 25px;
      right: 10px;

      button {
        width: 40px;
        height: 40px;
        padding: 0;
        display: inline-block;
        border: none;
        background-color: #ccc;
        border-radius: 50%;
        @include transition(all 0.2s);

        img {
          max-height: 20px;
          max-width: 20px;
          position: relative;
          top: -2px;
        }

        &:hover {
          background-color: $blue;
        }

        &.delete:hover {
          background-color: $red;
        }
      }

      button + button {
        margin-left: 5px;
      }
    }

    &_body {
      & > a {
        min-height: 70px;
        position: relative;
        padding: 10px 10px 10px 90px;
        display: block;
      }

      figure {
        position: absolute;
        left: 10px;
        top: 5px;

        img {
          height: 60px;
          width: 60px;
          border-radius: 50%;
        }
      }

      p {
        margin-bottom: 0;
        margin-top: 5px;
        font-size: 17px;
      }

      time {
        display: block;
        font-size: 15px;
        color: #999;
      }

      .notification_article {
        padding-top: 10px;

        .notification_article_image {
          margin-right: 10px;
          vertical-align: top;
        }

        .notification_article_title {
          display: inline-block;
          width: calc(100% - 55px);
          font-weight: bold;
          vertical-align: bottom;
          word-break: break-word;
        }
      }
    }
  }

  &_items {
    padding-bottom: 5px;
  }
}

@include sm {
  .notifications {
    &_items {
      margin-top: 25px;
    }

    &_item {
      padding-right: 50px;

      &_options {
        top: 15px;
        max-width: 45px;

        button {
          width: 35px;
          height: 35px;

          &.delete:hover {
            background-color: #ccc !important;
          }
        }

        button + button {
          margin-left: 0;
          margin-top: 7px;
        }
      }

      &_body {
        & > a {
          padding-left: 60px;
          min-height: 90px;
        }

        figure {
          left: 0;

          img {
            height: 50px;
            width: 50px;
          }
        }

        p {
          margin-top: 0;
          font-size: 15px;
        }

        time {
          font-size: 14px;
          margin-top: 5px;
        }
      }
    }
  }
}
