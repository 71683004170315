@import '../_variables.scss';
// underhead
.underhead {
  background-color: rgba(65, 117, 223, 1);
  background: linear-gradient(180deg, rgba(65, 117, 223, 1) 0%, rgba(33, 78, 170, 1) 100%);
  color: #fff;
  padding-top: 30px;
  padding-bottom: 30px;
  h1 {
    font-size: 45px;
    font-weight: bold;
    margin-bottom: 40px;
  }
  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style-type: none;
    li {
      position: relative;
      display: inline-block;
      a {
        opacity: 0.7;
      }
      &:last-child {
        &:after {
          display: none;
        }
      }
      &:after {
        content: '';
        position: absolute;
        right: -18px;
        top: 10px;
        width: 8px;
        height: 8px;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        opacity: 0.7;
        @include transform(rotate(-45deg));
      }
    }
    li + li {
      margin-left: 30px;
    }
  }
}
@include md {
  .underhead {
    h1 {
      font-size: 38px;
    }
  }
}
@include sm {
  .underhead {
    padding-top: 25px;
    padding-bottom: 25px;
    h1 {
      font-size: 28px;
      margin-bottom: 10px;
    }
    ul {
      li + li {
        margin-left: 25px;
      }
      li {
        font-size: 13px;
        &:after {
          top: 7px;
          right: -15px;
          width: 7px;
          height: 7px;
        }
      }
    }
  }
}
