@import './_variables.scss';

// profile pages
.profile {
  &.section {
    overflow: initial;
    padding-top: 0;
  }

  & > .container {
    margin-top: -40px;
  }

  &_head {
    background-color: #222;
    background-size: cover;
    background-position: center;
    padding-top: 30px;
    padding-bottom: 60px;
    text-align: center;

    &_image {
      position: relative;
      display: inline-block;
      margin-bottom: 5px;

      figure {
        img {
          height: 160px;
          width: 160px;
          border-radius: 50%;
          border: 2px solid #fff;
          object-fit: cover;
        }
      }

      & > a {
        padding: 0;
        display: inline-block;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        border: none;
        background-color: #fff;
        position: absolute;
        right: 5px;
        bottom: 12px;
        @include transition(all 0.2s);

        img {
          margin-top: 15px;
          height: 24px;
        }

        &:hover {
          background-color: $gray;
        }
      }

      span {
        display: inline-block;
        position: absolute;
        bottom: 8px;
        left: 0;
        right: 0;
        margin: 0 auto;
        border-radius: 50%;
        border: 2px solid #fff;
        background-color: $dark;
        width: 18px;
        height: 18px;

        &.online {
          background-color: $green;
        }
      }

      .svg_edit {
        position: absolute;
        right: 0;
        bottom: 0;
        margin: 0;
        pointer-events: none;
      }
    }

    &_status {
      font-size: 14px;
      font-weight: 500;
      color: #999;
      margin-bottom: 5px;

      &.online {
        color: $green;
      }
    }

    h1 {
      color: #fff;
      margin-bottom: 10px;
      font-size: 28px;
      font-weight: 400;
    }

    &_icons {
      min-width: 100px;
      display: inline-block;
      padding: 5px 7px;
      background-color: #fff;
      border-radius: 5px;
      margin-bottom: 10px;

      span {
        display: inline-block;
        margin: 0 3px;
      }

      img {
        height: 20px;
      }
    }

    &_buttons {
      .button {
        margin: 5px 5px 0;
      }
    }
  }

  &_list {
    background-color: #fff;
    border-radius: 5px;
    display: block;
    list-style-type: none;
    margin-bottom: 30px;
    padding-left: 0;
    overflow: hidden;
    @include boxShadow;

    li {
      border-bottom: 1px solid #eee;

      &:last-child {
        border: none;
      }

      a {
        display: block;
        padding: 10px 32px 10px 45px;
        position: relative;
        @include transition(all 0.2s);

        &:hover {
          background-color: $gray;
        }

        img {
          position: absolute;
          left: 15px;
          top: 13px;
          height: 20px;
        }

        &:after {
          content: '';
          position: absolute;
          right: 15px;
          top: 19px;
          width: 8px;
          height: 8px;
          border-right: 2px solid $dark;
          border-bottom: 2px solid $dark;
          @include transform(rotate(-45deg));
        }

        .badge {
          float: right;
        }
      }
    }
  }

  &_info {
    background-color: #fff;
    padding: 20px 15px 10px;
    text-align: center;
    border-radius: 5px;
    margin-bottom: 30px;
    @include boxShadow;

    p {
      margin-bottom: 10px;

      b,
      strong {
        display: block;
      }
    }
  }

  &_review {
    padding-top: 10px;
    margin-bottom: 40px;

    h5 {
      text-align: center;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 10px;
    }

    & > a {
      display: block;
      background-color: $dark;
      text-align: center;
      border-radius: 5px;
      padding-top: 10px;

      &.like {
        background-color: $green;
      }

      &.neutral {
        background-color: $blue;
      }

      &.dislike {
        background-color: $red;
      }
    }

    span {
      display: block;
      color: #fff;
      font-size: 28px;
      line-height: 28px;
      font-weight: bold;
      margin-bottom: 7px;
    }

    figure {
      display: inline-block;
      margin-bottom: -30px;
      background-color: #fff;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      @include boxShadow;

      img {
        height: 25px;
        margin-top: 15px;
      }
    }

    &_item {
      background-color: #fff;
      margin: 0 0 5px !important;
      border-bottom: 1px solid #eee;
      border-radius: 5px;
      padding: 15px 50px 15px 5px;
      @include boxShadow;
      position: relative;

      &_user {
        position: relative;
        padding-left: 90px !important;

        figure {
          position: absolute;
          left: 15px;

          img {
            height: 60px;
            width: 60px;
            border-radius: 50%;
          }
        }

        h3 {
          font-size: 16px;
          margin-top: 0px;
          margin-bottom: 5px;
        }

        & > div {
          display: inline-block;
          background-color: $light;
          padding: 5px;
          border-radius: 5px;

          span {
            display: inline-block;
            margin: 0 3px;
          }

          img {
            height: 17px;
          }
        }
      }

      &_desc {
        h4 {
          font-size: 16px;
          font-weight: 600;
        }

        p {
          font-size: 15px;
        }
      }

      &_date {
        text-align: right;
        font-size: 15px;
        padding-top: 5px;
      }

      &_icon {
        position: absolute;
        right: 20px;
      }
    }
  }

  &_settings {
    display: block;
    border-radius: 5px;
    background-color: #fff;
    padding: 20px;
    margin-bottom: 30px;
    @include boxShadow;

    h3 {
      font-weight: bold;
      margin-bottom: 15px;
    }

    &_input {
      position: relative;

      .form_default_input {
        input {
          padding-right: 40px;
        }
      }
    }

    &_btn {
      position: absolute;
      z-index: 3;
      right: 0;
      top: 5px;
      height: 35px;
      width: 35px;
      border: none;
      padding: 0;
      text-align: center;
      border-radius: 50%;
      background-color: #fff;

      img {
        height: 20px;
        position: relative;
        top: -2px;
      }

      &.save {
        background-color: $green;
      }

      &.edit {
        img {
          opacity: 0.5;
        }
      }

      &.add {
        background-color: $blue;
      }
    }
  }
}

@include sm {
  .profile {
    &_head {
      h1 {
        font-size: 24px;
      }

      &_image {
        figure {
          img {
            height: 120px;
            width: 120px;
          }
        }

        & > a {
          right: -12px;
          bottom: 8px;
        }

        .svg_edit img {
          height: 15px;
          width: 15px;
        }
      }
    }

    &_review {
      h5 {
        font-size: 15px;
      }

      &_item {
        &_user {
          padding-left: 0px !important;
          margin-bottom: 15px;
          display: flex;
          align-items: center;
          gap: 10px;

          figure {
            position: relative;
            display: inline;

            img {
              border-radius: 50%;
              height: 60px;
              width: 60px;
            }
          }

          h3 {
            margin-left: 15px;
          }
        }

        &_date {
          padding-top: 0;
          text-align: left;
        }

        &_desc {
          button {
            margin-bottom: 10px;
          }
        }
      }
    }

    &_settings {
      padding-left: 15px;
      padding-right: 15px;
    }
  }
}
