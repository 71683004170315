@import '../_variables.scss';
// header
.mobile_tabs {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: 1000;
  background-color: #fff;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  & > .d-flex {
    height: 80px;
  }
  .col {
    display: flex;
    padding: 5px;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    svg,
    img {
      height: 30px;
      width: 30px;
      @include transition(all 0.2s);
    }
    span {
      display: block;
      margin-top: 3px;
      font-size: 15px;
      @include transition(all 0.2s);
    }
    a,
    svg path {
      @include transition(all 0.2s);
    }
  }
  &_add {
    a {
      height: 65px;
      width: 65px;
      line-height: 60px;
      display: inline-block;
      border-radius: 50%;
      background-color: $blue;
      text-align: center;
      border: 2px solid #fff;
      box-shadow: 0 6px 10px rgba(65, 117, 223, 0.3);
      &.active {
        background-color: $dark;
      }
    }
  }
  &_messages {
    div {
      position: relative;
      span {
        background-color: $red;
        position: absolute;
        top: -8px;
        right: -8px;
        width: 24px;
        height: 24px;
        line-height: 24px;
        color: #fff;
        border-radius: 50%;
        text-align: center;
        margin-top: 0;
        font-size: 12px;
      }
    }
  }
  a.active {
    color: $blue;
    svg {
      path {
        fill: $blue;
      }
    }
  }
}

@include sm {
  .mobile_tabs {
    & > .d-flex {
      height: 60px;
    }
    .col {
      svg,
      img {
        height: 20px;
        width: 20px;
      }
      span {
        margin-top: 0;
        font-size: 12px;
      }
    }
    &_messages {
      div {
        span {
          right: -6px;
          top: -4px;
          width: 18px;
          height: 18px;
          font-weight: 600;
          line-height: 18px;
          font-size: 11px;
        }
      }
    }
    &_add {
      a {
        height: 50px;
        width: 50px;
        line-height: 44px;
      }
    }
  }
}
