@import './_variables.scss';

.home {
  &_banner {
    background-color: $dark;
    color: #fff;
    background-position: center;
    background-size: cover;
    padding: 10px 0;

    &_txt {
      position: relative;

      // padding-left: 135px;
      figure {
        display: inline-block;
        margin-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100px;
        height: 100px;
        border-radius: 50%;
        background-color: rgba(0, 0, 0, 0.25);
        text-align: center;
        padding-top: 25px;

        img {
          display: inline-block;
          height: 50px;
        }
      }

      h1 {
        text-transform: uppercase;
        font-size: 48px;
        font-weight: bold;
        margin-bottom: 0;
      }

      p {
        font-size: 23px;
        margin-bottom: 0;
      }
    }
  }

  &_categories.section {
    padding-bottom: 0px;
    padding-top: 15px;
  }

  &_category {
    text-align: center;
    margin-bottom: 20px;
    @include text-wrap;

    & > a {
      padding: 8px 0px;
      display: block;
      border: 1px solid transparent;
      @include transition(all 0.2s);

      &:hover {
        border-color: $blue;
        box-shadow: 0 0 15px rgba(65, 117, 223, 0.2);
      }
    }

    img {
      height: 40px;
      width: 40px !important;
      margin-bottom: 10px;
    }

    h3 {
      font-size: 12px;
      margin-bottom: 0;
    }
  }

  &_tabs {
    background-color: #fff;
    padding: 8px 6px;
    border-radius: 8px;
    margin-bottom: 15px;
    display: inline-block;

    li {
      display: inline-block;

      a {
        padding: 5px 10px;
        border-radius: 8px;

        @include transition(all 0.2s);

        &:hover {
          background-color: #f2f2f2;
        }
      }

      .active {
        background-color: $red !important;
        color: #fff;
      }
    }

    li + li {
      margin-left: 5px;
    }

    &_more {
      float: right;

      a {
        color: $blue !important;
        text-decoration: underline !important;
      }
    }
  }
}

@include sm {
  .home {
    &_banner {
      padding: 45px 0;

      &_txt {
        // padding-left: 75px;
        figure {
          width: 60px;
          height: 60px;
          padding-top: 15px;

          img {
            height: 30px;
          }
        }

        h1 {
          font-size: 28px;
        }

        p {
          font-size: 20px;
          line-height: 1.2;
        }
      }
    }

    &_categories.section {
      // padding-top: 45px;
      // padding-bottom: 30px;
    }

    &_categories .row {
      //display: grid;
      //grid-template-columns: repeat(6, 1fr);
      //overflow: scroll;
    }

    .justify-center {
      justify-content: center;
      display: flex !important;
    }

    &_categories .row .col-4 {
      max-width: 100%;
    }

    &_categories .categories_row .col-4 {
      width: 20%;
      flex: 0 0 20%;
    }

    &_category {
      margin-bottom: 15px;

      & > a {
        padding: 5px;
        border: none !important;
        box-shadow: none !important;
      }

      img {
        height: 40px;
        margin-bottom: 10px;
      }

      h3 {
        font-size: 0.75rem;
      }
    }

    &_tabs {
      padding: 5px;
      width: 100%;

      li {
        a {
          padding: 5px 7px;
          font-size: 12.5px;
        }
      }
    }
  }
}

.text-end {
  text-align: end;
}

@media (max-width: 410px) {
  .home_category h3 {
    font-size: 0.65rem !important;
  }
}

@media (max-width: 415px) {
  .banner-desktop {
    display: none;
  }

  .banner-mobile {
    display: block;
  }
}

@media (min-width: 416px) {
  .home_category h3 {
    font-size: 0.65rem !important;
  }

  .banner-desktop {
    display: block;
  }

  .banner-mobile {
    display: none;
  }
}
