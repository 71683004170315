@import '../_variables.scss';
// header
.header {
  padding-top: 15px;
  padding-bottom: 15px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  &_wrap {
    height: auto;
  }
  &_fixed {
    width: 100%;
    top: 0;
    z-index: 1000;
    position: fixed;
    padding-top: 10px;
    padding-bottom: 10px;
    opacity: 0;
    animation: fadeToBottom 0.25s ease 0s forwards;
    .header_logo {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding-top: 8px;
      img {
        height: 25px;
      }
    }
  }
  &_logo {
    display: inline-block;
    align-self: center;
    width: 65px;
    height: 65px;
    min-width: 65px;
    border-radius: 50%;
    text-align: center;
    padding-top: 15px;
    background-color: $gray;
    img {
      height: 35px;
    }
  }
  &_search {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    align-self: center;
    input {
      background-color: $gray;
      border-radius: 30px;
      border: none;
      height: 45px;
      line-height: 45px;
      padding: 0 15px 0 50px;
      min-width: 400px;
      font-weight: 500;
    }
    button {
      left: 0;
      top: 0;
      position: absolute;
      background-color: transparent;
      border: none;
      width: 45px;
      height: 45px;
      border-radius: 50%;
      padding: 0;
      img {
        position: relative;
        top: -2px;
        height: 17px;
      }
    }
  }
  &_indicator {
    display: inline-block;
    position: relative;
    margin-left: 30px;
    align-self: center;
    &_btn {
      display: inline-block;
      position: relative;
      width: 45px;
      height: 45px;
      line-height: 45px;
      font-size: 0;
      border-radius: 50%;
      border: none;
      text-align: center;
      padding: 0;
      background-color: $gray;
      img {
        max-height: 25px;
        max-width: 25px;
      }
      span {
        display: inline-block;
        position: absolute;
        right: 8px;
        top: 5px;
        width: 18px;
        height: 18px;
        font-weight: 600;
        line-height: 18px;
        font-size: 11px;
        border-radius: 50%;
        background-color: $red;
        color: #fff;
      }
    }
  }
  &_messages {
    img {
      position: relative;
      top: 2px;
    }
  }
  &_user {
    margin-left: 30px;
    display: inline-block;
    position: relative;
    align-self: center;
    & > button {
      background-color: transparent;
      border: none;
      padding: 0;
      position: relative;
      padding-right: 18px;
      font-weight: 500;
      &:after {
        content: '';
        display: inline-block;
        height: 8px;
        width: 8px;
        position: absolute;
        right: 0;
        top: 15px;
        border-right: 2px solid #222;
        border-bottom: 2px solid #222;
        @include transform(rotate(45deg));
      }
      img {
        height: 45px;
        width: 45px;
        border-radius: 50%;
        margin-right: 8px;
      }
    }
  }
}

@include lg {
  .header {
    padding-top: 10px;
    padding-bottom: 10px;
    position: fixed;
    top: 0;
    z-index: 1000;
    width: 100%;
    opacity: 1;
    animation: none;
    &_wrap {
      //height: 65px;
    }
    &_logo {
      height: 45px;
      width: 45px;
      min-width: 45px;
      padding-top: 8px;
      img {
        height: 25px;
      }
    }
    &_user {
      & > button {
        img {
          margin-right: 0;
        }
      }
      span {
        display: none;
      }
    }
  }
}
@include md {
  .header {
    &_search {
      width: 100%;
      display: block;
      input {
        width: 100%;
        min-width: 200px;
      }
    }
    &_notifications {
      margin-left: 0;
    }
  }
}
@include sm {
  .header {
    &_left {
      padding-right: 0 !important;
      gap: 10px;
    }
    &_search {
      margin-left: 0;
      width: 80%;
      margin: 15px 0;
    }
  }
}

.arrow_categories_holder {
  position: relative;
  top: 5px;
  display: inline-block;

  width: 100%;

  text-align: center;
}

.arrow_categories_up {
  transform: rotate(180deg);
}

.arrow_categories {
  display: inline-block;

  text-align: center;

  cursor: pointer;
}

.arrows_down {
  display: inline-block;

  width: 60px;
  height: 30px;

  object-fit: contain;
}

.top0 {
  top: 0px;
}

.message_indicator_holder {
  display: inline-block;
  position: absolute;
  top: 0px;
  width: 45px;
  height: 45px;
  line-height: 45px;
  font-size: 0;
  border-radius: 50%;
  border: none;
  text-align: center;
  padding: 0;

  span {
    display: inline-block;
    position: absolute;
    right: 0px;
    top: 5px;
    width: 18px;
    height: 18px;
    font-weight: 600;
    line-height: 18px;
    font-size: 11px;
    border-radius: 50%;
    background-color: #e51a1a;
    color: #fff;
  }
}

@media (max-width: 992px) {
  .header_container .col-4 {
    flex: 0 0 8%; //.667
    max-width: 8%; //.667

    margin-bottom: 0px;
  }
}

@media (min-width: 992px) {
  .header_container .col-lg-2 {
    flex: 0 0 8%; //.667
    max-width: 8%; //.667

    margin-bottom: 0px;
  }
}

@media (max-width: 768px) {
  .arrow_categories_holder {
    top: 0px;
  }
}

@media (max-width: 425px) {
  .header_container {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}
