.slick-list,
.slick-slider,
.slick-track {
  position: relative;
  display: block;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slider {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: 0;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  top: 0;
  left: 0;
}
.slick-track:after,
.slick-track:before {
  display: table;
  content: '';
}
.slick-track:after {
  clear: both;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
[dir='rtl'] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}
// custom slick style
.slick {
  &-initialized .slick-slide {
    width: 60%;
    outline: 0 !important;
  }
  &-prev,
  &-next {
    text-align: center;
    position: absolute;
    z-index: 4;
    top: 50%;
    border-radius: 50%;
    width: 55px;
    height: 55px;
    border: none;
    font-size: 0;
    padding: 0;
    background-color: #fff;
    @include transition(all 0.2s);
    @include transform(translate(0%, -50%));
    &:hover {
      background-color: #f5f6f8;
    }
    &:after {
      content: '';
      display: inline-block;
      width: 15px;
      height: 15px;
      border-left: 2px solid #222;
      border-bottom: 2px solid #222;
    }
  }
  &-prev {
    left: 15px;
    &:after {
      @include transform(rotate(45deg));
      position: relative;
      right: -3px;
    }
  }
  &-next {
    right: 15px;
    &:after {
      @include transform(rotate(-135deg));
      position: relative;
      left: -3px;
    }
  }
  &-dots {
    position: absolute;
    bottom: 20px;
    padding-left: 0;
    list-style-type: none;
    margin-bottom: 0;
    width: 100%;
    text-align: center;
    li {
      margin-left: 2px;
      margin-right: 2px;
      display: inline-block;
      button {
        background-color: #222;
        font-size: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        padding: 0;
        border: none;
        border: 2px solid #fff;
      }
      &.slick-active {
        button {
          background-color: #ccc;
        }
      }
    }
  }
}
