@import './_variables.scss';

.messages {
  &_left {
    margin-bottom: 20px;

    ul {
      padding-left: 0;
      list-style-type: none;

      li {
        display: block;
        margin-bottom: 10px;

        &.active {
          a {
            background-color: $gray;
          }
        }

        a {
          position: relative;
          display: block;
          background-color: #fff;
          padding: 12px 45px 12px 15px;
          font-size: 17px;
          font-weight: 500;
          border: 2px solid #fff;
          @include boxShadow;
          @include transition(all 0.2s);

          &:hover {
            background-color: $gray;
          }

          .badge {
            position: absolute;
            right: 15px;
            top: 12px;
          }
        }
      }
    }
  }

  &_check {
    position: relative;

    input {
      position: absolute;
      visibility: hidden;
      width: 0;
      height: 0;
    }

    span {
      display: inline-block;
      border: 2px solid #ccc;
      width: 18px;
      height: 18px;
      background-color: #fff;
      cursor: pointer;
      @include transition(all 0.2s);

      &:after {
        content: '';
        position: absolute;
        width: 6px;
        height: 10px;
        left: 0;
        right: 0;
        top: 3px;
        bottom: 0;
        margin: 0 auto;
        border-right: 2px solid $blue;
        border-bottom: 2px solid $blue;
        @include transform(rotate(45deg));
        opacity: 0;
        @include transition(all 0.2s);
      }
    }

    input:checked + span {
      border-color: $blue;

      &:after {
        opacity: 1;
      }
    }
  }

  &_head {
    position: relative;
    background-color: #fff;
    padding: 10px 10px 10px 45px;
    @include boxShadow;

    &.row {
      margin: 0 0 15px;
    }

    .messages_check {
      display: inline-block;
      position: absolute;
      left: 15px;
      top: 22px;
    }

    button {
      width: 100%;
      display: block;
      border: none;
      background-color: $light;
      min-height: 40px;
      border-radius: 5px;
      @include transition(all 0.2s);

      &:hover {
        background-color: $gray;
      }
    }

    select {
      width: 100%;
      display: block;
      height: 40px;
      border: none;
      border-radius: 5px;
      background-color: $dark;
      color: #fff;
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  &_items {
    padding-bottom: 5px;
  }

  &_item {
    position: relative;
    background-color: #fff;
    @include boxShadow;
    margin-bottom: 10px;

    &_top {
      position: relative;
      padding: 10px 10px 10px 45px;
      align-items: center;
      border-bottom: 1px dashed #ccc;

      &.row {
        margin: 0;
      }

      .messages_check {
        position: absolute;
        left: 15px;
        top: 15px;
      }

      h3 {
        font-size: 15px;
        margin-bottom: 0;
        @include ellipsis;
      }
    }

    &_actions {
      text-align: right;

      button,
      a {
        text-align: center;
        display: inline-block;
        font-size: 0;
        border: none;
        padding: 0;
        line-height: 27px;
        width: 27px;
        height: 27px;
        border-radius: 50%;
        background-color: #ccc;
        vertical-align: middle;
        @include transition(all 0.2s);

        img {
          height: 14px;
        }

        &:hover {
          background-color: $dark;
        }

        &.mark {
          &:hover,
          &.active {
            background-color: $green;
          }
        }

        &.delete {
          &:hover {
            background-color: $red;
          }
        }
      }

      button + button {
        margin-left: 8px;
      }
    }

    &_bottom {
      position: relative;
      padding: 10px;

      &.row {
        margin: 0;
      }
    }

    &_user {
      position: relative;
      padding-left: 65px !important;
      min-height: 55px;

      figure {
        margin-bottom: 0;
        position: absolute;
        left: 0;
        top: 0;

        img {
          height: 55px;
          width: 55px;
          border-radius: 50%;
        }
      }

      h4 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        margin-top: 5px;
        @include ellipsis;
      }

      p {
        font-size: 15px;
        color: #999;
        margin-bottom: 0;
        @include ellipsis;
      }
    }

    &_time {
      text-align: right;

      time {
        display: block;
        font-size: 13px;
        margin-bottom: 5px;
        color: #999;
      }

      span {
        text-align: center;
        display: inline-block;
        width: 23px;
        height: 23px;
        font-weight: 600;
        line-height: 23px;
        font-size: 12.5px;
        border-radius: 50%;
        background-color: $blue;
        color: #fff;
      }
    }
  }
}

.message {
  &_wrap {
    @include boxShadow;
  }

  &_head {
    position: relative;
    background-color: #fff;
    padding: 10px;
    border-bottom: 1px dashed #ccc;

    &.row {
      margin: 0;
    }

    h3 {
      background-color: $light;
      display: inline-block;
      padding: 5px 10px;
      margin-bottom: 0;
      font-size: 17px;
    }
  }

  &_actions {
    text-align: right;

    button,
    a {
      margin-left: 5px;
      text-align: center;
      display: inline-block;
      font-size: 0;
      border: none;
      padding: 0;
      line-height: 30px;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      background-color: #ccc;
      vertical-align: middle;
      @include transition(all 0.2s);

      img {
        height: 14px;
      }

      &:hover {
        background-color: $dark;
      }

      &.mark {
        &:hover,
        &.active {
          background-color: $green;
        }
      }

      &.delete {
        &:hover {
          background-color: $red;
        }
      }
    }
  }

  &_user {
    position: relative;
    background-color: #fff;
    padding: 10px 10px 10px 80px;
    min-height: 75px;
    border-bottom: 1px solid #ccc;

    figure {
      margin-bottom: 0;
      position: absolute;
      left: 15px;
      top: 10px;

      img {
        height: 55px;
        width: 55px;
        border-radius: 50%;
      }
    }

    h4 {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      margin-top: 5px;
    }

    p {
      font-size: 15px;
      color: #999;
      margin-bottom: 0;
    }
  }

  &_items {
    @include text-wrap;
    padding: 20px;
    background-color: $gray;
    border-left: 1px solid #eee;
    border-right: 1px solid #eee;
    max-height: 600px;
    overflow-y: auto;
  }

  &_date {
    padding: 10px 0;
    text-align: center;
    display: block;
    text-transform: uppercase;
    font-size: 14px;
    color: #999;
    font-weight: 500;
    letter-spacing: 0.5px;
  }

  &_item {
    padding-top: 10px;
    padding-bottom: 10px;

    &_right {
      text-align: right;
    }
  }

  &_bubble {
    position: relative;
    display: inline-block;
    max-width: 500px;
    width: 90%;
    border-radius: 10px;
    background-color: #fff;
    padding: 15px;
    text-align: left;
    @include boxShadow;

    &:after {
      content: '';
      display: inline-block;
      height: 15px;
      width: 16px;
      background-color: #fff;
      position: absolute;
      left: -8px;
      bottom: 25px;
      @include transform(rotate(45deg));
    }

    p {
      margin-bottom: 10px;
    }

    time {
      display: block;
      text-align: right;
      font-size: 14px;
    }

    &.blue {
      background-color: $blue;
      color: #fff;

      &:after {
        background-color: $blue;
        left: auto;
        right: -8px;
      }
    }
  }

  &_type {
    border-top: 1px solid #ccc;
    background-color: #fff;
    padding: 20px;

    textarea {
      background-color: $light;
      width: 100%;
      display: block;
      border-radius: 5px;
      border: none;
      margin-bottom: 20px;
      padding: 15px;
      resize: none;
      @include transition(all 0.2s);

      &:focus {
        background-color: $gray;
      }
    }

    .button {
      img {
        margin-right: 10px;
        height: 20px;
        position: relative;
        top: -2px;
      }
    }
  }
}

@include sm {
  .messages {
    &_left {
      &_mobile {
        position: relative;
        margin-top: 25px;
        display: block;
        position: relative;
        width: 100%;
        background-color: $dark;
        color: #fff !important;
        text-align: left;
        border: none;
        border-radius: 5px;
        padding: 10px 35px 10px 10px;

        .badge {
          margin-left: 5px;
          font-size: 13px;
          background-color: $blue;
        }

        &:after {
          content: '';
          display: inline-block;
          height: 10px;
          width: 10px;
          position: absolute;
          right: 15px;
          top: 15px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
        }

        .default_dropdown {
          width: 100%;

          a {
            .badge {
              margin-left: 5px;
              font-size: 13px;
              float: right;
            }
          }
        }
      }
    }

    &_head {
      button {
        margin-top: 5px;
        margin-bottom: 5px;
      }
    }
  }

  .message {
    &_head {
      h3 {
        font-size: 16px;
      }
    }

    &_actions {
      padding-top: 10px;
      text-align: left;
    }

    &_items {
      max-height: 400px;
    }

    &_bubble {
      p {
        font-size: 15px;
      }
    }

    &_date {
      font-size: 13px;
      padding-top: 5px;
      padding-bottom: 5px;
    }

    &_type {
      padding: 15px;

      textarea {
        margin-bottom: 5px;
      }

      .button {
        width: 100%;
        margin-top: 10px;
      }
    }
  }
}
