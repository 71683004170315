@import '../_variables.scss';
// not_found
.not_found {
  text-align: center;
  padding: 120px 15px;
  h1 {
    font-size: 160px;
    line-height: 1;
    margin-bottom: 15px;
    font-weight: bold;
    color: $dark;
  }
  h2 {
    font-size: 32px;
    font-weight: 400;
    margin-bottom: 35px;
  }
}
@include sm {
  .not_found {
    padding-top: 70px;
    padding-bottom: 70px;
    h1 {
      font-size: 100px;
      margin-bottom: 10px;
    }
    h2 {
      font-size: 24px;
    }
  }
}
