@import '../_variables.scss';
// footer
.footer {
  margin-top: auto;
  background-color: #0d1931;
  color: #fff;
  a {
    @include transition(all 0.2s);
    &:hover {
      color: $green;
    }
  }
  &_wrapper {
    padding-top: 70px;
    padding-bottom: 50px;
    h5 {
      font-size: 20px;
      padding-bottom: 15px;
      margin-bottom: 15px;
      border-bottom: 1px solid $dark;
      position: relative;
      &:before {
        content: '';
        display: inline-block;
        width: 70px;
        height: 1px;
        position: absolute;
        left: 0;
        bottom: 0;
        background-color: $green;
      }
    }
    ul {
      padding-left: 0;
      margin-bottom: 20px;
      display: block;
      color: #9caac6;
      font-size: 15px;
      li {
        display: block;
        margin-bottom: 7px;
      }
    }
    .footer_categories {
      li {
        display: inline-block;
        margin-right: 10px;
      }
    }
  }
  &_copyright {
    background-color: $dark;
    padding-top: 25px;
    padding-bottom: 25px;
    p {
      margin-bottom: 0;
      font-size: 15px;
    }
    ul {
      font-size: 15px;
      display: block;
      margin-bottom: 0;
      text-align: right;
      li {
        display: inline-block;
      }
      li + li {
        margin-left: 15px;
      }
    }
  }
}
