@import '../_variables.scss';

// single post
.post {
  padding-bottom: 20px;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
  @include text-wrap;

  .title-box {
    min-height: 92px;
    position: relative;
  }

  & > a {
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 0 13px rgba(0, 0, 0, 0.05);
    display: block;
    height: 100%;
    padding: 10px;
    @include transition(all 0.2s);

    &:hover {
      box-shadow: 0 0 15px rgba(0, 0, 0, 0.15);
    }
  }

  &.sold {
    opacity: 0.5;
    position: relative;
  }

  &.sold::before {
    content: 'Završeno';
    position: absolute;
    background: #e51a1a;
    padding: 5px;
    color: #fff;
    font-weight: 900;
    border-radius: 5px;
    z-index: 2;
    text-align: center;
    width: 50%;
    top: 25%;
    left: 25%;
  }

  figure {
    position: relative;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 151px;
    /* border: 1
px
 solid #000; */
    border-radius: 10px;

    // box-shadow: 0 0 2px 2px #bbb;
    img {
      border-radius: 5px;

      border-radius: 5px;
      // width: 148px;
      // height: 148px;
      object-fit: cover;
    }
  }

  h3 {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  p {
    font-size: 14px;
    color: #000;
    margin-bottom: 10px;
    font-weight: 900;

    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  &_info {
    margin-right: 5px;
    margin-bottom: 5px;
    background-color: $dark;
    color: #fff;
    display: inline-block;
    padding: 0 8px;
    border-radius: 5px;
    height: 25px;
    line-height: 25px;
    font-size: 13px;
    vertical-align: middle;
  }

  &_sale {
    background-color: $red;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    top: 10px;
    right: 10px;
    position: absolute;
  }

  &_special {
    background-color: #008080;
  }

  &_price {
    background-color: $blue;
    font-weight: bold;
    font-size: 14px;
    position: absolute;
    bottom: 0;
    left: 5px;
  }

  &_hide {
    background-color: $red;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    top: 0px;
    left: 0px;
    font-weight: 700;
    position: absolute;
  }

  &.discounted {
    & > a {
      background-color: #cccccc !important;
    }

    .post-credit {
      background-color: #fff !important;
    }
  }
}

// number of posts in one row
.posts {
  &_6 {
    .col {
      @include column(16.666667%);
    }
  }

  &_5 {
    .col {
      @include column(20%);
    }
  }

  &_4 {
    .col {
      @include column(25%);
    }
  }

  &_3 {
    .col {
      @include column(33.3333%);
    }
  }

  &_2 {
    .col {
      @include column(50%);
    }
  }

  &_1 {
    .col {
      @include column(100%);
    }
  }
}

@include md {
  .posts {
    &_3,
    &_4,
    &_5,
    &_6 {
      .col {
        @include column(33.3333%);
      }
    }
  }
}

@include sm {
  .post {
    & > a {
      padding: 8px;
    }

    h3 {
      font-size: 17px;
    }

    &_info {
      letter-spacing: 0;
      padding-left: 5px;
      padding-right: 5px;
      font-size: 10px;
      margin-right: 3px;
      font-weight: 500;
    }

    &_price {
      font-size: 13px;
      font-weight: bold;
    }
  }

  .posts {
    &_3,
    &_4,
    &_5,
    &_6 {
      .col {
        @include column(50%);
      }
    }

    &_2,
    &_3,
    &_4,
    &_5,
    &_6 {
      margin-left: -5px !important;
      margin-right: -5px !important;

      & > div,
      & > article {
        padding-left: 5px !important;
        padding-right: 5px !important;
      }

      .post {
        padding-bottom: 10px;
      }
    }
  }
}

.no-image {
  height: 105px !important;
  width: 144px !important;
}

.featured {
  overflow: unset !important;
}

.featured a {
  border: 1px solid #bbb !important;
  // border-radius: 10px !important;
}
