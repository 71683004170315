@import './_variables.scss';

.category {
  &_aside {
    margin-bottom: 20px;

    .collapse_default {
      margin-bottom: 0;
    }

    .form_default_check {
      margin-bottom: 10px;
    }

    &_list {
      margin-bottom: 0;
      padding: 10px;
      list-style-type: none;

      & .has-children {
        align-items: flex-start !important;
        flex-direction: column;
      }

      & .has-children > a {
        font-weight: 900;
      }

      & .has-children ul li {
        justify-content: flex-start !important;
      }

      li {
        display: block;
        border-bottom: 1px solid #eee;

        .badge {
          padding: 0 8px;
          min-width: 25px;
          margin: -3px 0 -3px 5px;
          vertical-align: initial;
          background-color: $gray;
          color: $dark;
        }

        &:last-child {
          border: none;
        }

        &.is-active {
          font-weight: 900;
        }

        a {
          display: block;
          padding: 5px;
          @include transition(all 0.2s);

          &:hover {
            background-color: $light;
          }
        }
      }

      ul {
        padding-left: 10px;
      }
    }

    &_range {
      padding: 10px;

      label {
        font-size: 15px;
        font-weight: 400;
      }

      input {
        margin-bottom: 5px;
        width: 100%;
        border-radius: 5px;
        padding-left: 10px;
        height: 40px;
        line-height: 40px;
        background-color: $light;
        border: 1px solid #eee;
      }
    }

    &_close,
    &_toggle {
      display: none;
    }
  }

  &_results {
    align-self: center;
    margin-bottom: 20px;
    min-height: 2rem;
    max-height: 2.5rem;

    h3 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 0;

      b {
        font-weight: bold;
      }
    }
  }

  &_options {
    margin-bottom: 10px;
    text-align: right;
  }

  &_option {
    margin-bottom: 10px;
    display: inline-block;

    label {
      display: inline-block;
      margin-right: 5px;
    }

    select {
      display: inline-block;
      height: 40px;
      line-height: 40px;
      padding-left: 10px;
      background-color: #fff;
      border: 1px solid #ccc;
      border-radius: 5px;
    }
  }

  &_option + &_option {
    margin-left: 25px;
  }
}

@include md {
  .category {
    &_right {
      .posts_4 {
        .col {
          @include column(50%);
        }
      }
    }
  }
}

@include sm {
  .category {
    padding-top: 60px !important;

    &_aside {
      position: fixed;
      z-index: 1000000;
      width: 100%;
      height: 100%;
      top: 0;
      left: -100%;
      opacity: 0;

      &.active {
        left: 0;
        opacity: 1;

        .category_aside_wrap {
          animation: fadeToRight 0.3s ease 0.2s forwards;
        }

        .category_aside_close {
          animation: fadeToCenter 0.3s ease 0.2s forwards;
        }
      }

      &_toggle {
        display: block;
        position: absolute;
        z-index: 100;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1) !important;
        top: -205px;
        width: 95%;
        left: 0;
        right: 0;
        margin: 15px auto;
        border: 1px solid $gray;
        border-radius: 5px;
        background-color: #fff;
        text-align: center;
        cursor: pointer;
        padding: 8px 15px;
        color: $blue;
        font-weight: 500;
        font-size: 17px;

        img {
          height: 23px;
          margin-right: 5px;
        }
      }

      &_close {
        z-index: 1;
        position: absolute;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
        display: block;
        opacity: 0;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &_wrap {
        position: relative;
        z-index: 2;
        max-width: 80%;
        background-color: #fff;
        left: 0;
        top: 0;
        opacity: 0;
        height: 100%;
        overflow-y: auto;
        @include transition(all 0.2s);
      }
    }

    &_results {
      h3 {
        font-size: 18px;
        margin-top: 29px;
      }
    }

    &_options {
      text-align: left;
      display: flex;
      justify-content: space-between;
    }

    &_option {
      @include column(48%);
      margin-left: 0 !important;
      display: block;

      select,
      label {
        width: 100%;
        display: block;
      }

      label {
        font-size: 14px;
        margin-bottom: 2px;
      }

      select {
        font-size: 15px;
        padding-left: 5px;
      }
    }
  }
}
