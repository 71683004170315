@import './_variables.scss';
@import './vendor/slick-slider.scss';

// detail
.edit-page {
  // background-color: #054781 !important;
  // background: #054781 !important;
  border-bottom: 1px solid #fff;
  position: relative;
  top: 15px;
}

.ReactModal__Content.ReactModal__Content--after-open {
  display: flex;
  justify-content: center;
  align-items: center;
}

// .ReactModal__Overlay label {
//   width: 80%;
//   margin: auto;
//   text-align: center;
// }

.ReactModal__Overlay label strong {
  display: block;
}

.ReactModal__Overlay .modal_user_image {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  margin: 0 8px;
  cursor: pointer;
}

.followers-text {
  display: none;
}

.follower:hover + .followers-text {
  display: block;
  position: absolute;
  top: -20px;
  font-weight: 900;
}

.delete-image {
  border: 0;
  background-color: #e51a1a;
  position: absolute;
  top: 10px;
  right: 50px;
  padding: 5px;
  border-radius: 10px;
}

.detail {
  .lightbox-link {
    display: block;
  }

  .edit {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
  }

  .edit-holder {
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    text-align: center;
  }

  .carousel .slider-wrapper {
    display: inline-block;
    // width: 100%;
  }

  .ReactModalPortal > .carousel-root {
    width: 100%;
  }

  // .carousel .slide img {
  //   width: 70% !important;
  //   vertical-align: top !important;
  //   border: 0 !important;
  // }

  .detail_user_image {
    height: 45px !important;
    width: 45px !important;
    border-radius: 50% !important;
    margin-right: 8px !important;
  }

  .container-medium {
    width: 60%;
  }

  @include text-wrap;

  .not_verified {
    color: #e51a1a;
  }

  &_slider {
    // background: rgb(37,39,42);
    // background: linear-gradient(180deg, rgba(37,39,42,1) 0%, rgba(0,0,0,1) 100%);
    position: relative;

    &_wrap {
      position: relative;

      & .swiper-wrapper {
        max-height: 300px;

        & img {
          max-height: 300px;
          object-fit: contain;

          @media screen and (max-width: 415px) {
            width: 100%;
          }
        }
      }
    }

    .sale_status {
      position: absolute;
      bottom: 0;
    }

    .slick-slide {
      // max-height: 300px;
    }

    &_item {
      // display: flex;
      // height: 630px;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.1);

      img {
        align-self: center;
        // max-height: 280px;
        min-width: 50px;
        width: 100%;
        height: 300px !important;
      }

      &.active:first-of-type {
        flex: 100%;
      }

      &:not(.active) {
        flex: 25%;
        justify-self: flex-end;
        align-self: flex-end;
        order: 2;
        margin: 0 5px;
      }
    }

    &_number {
      position: absolute;
      z-index: 10;
      bottom: 30px;
      right: 30px;
      min-width: 70px;
      height: 30px;
      line-height: 30px;
      font-weight: bold;
      padding-left: 5px;
      padding-right: 5px;
      background-color: rgba(0, 0, 0, 0.5);
      color: #fff;
      border-radius: 5px;
      text-align: center;

      span {
        display: inline-block;
        font-weight: 400;
        margin: 0 5px;
      }
    }

    &_circles {
      position: absolute;
      top: 30px;
      z-index: 10;
      width: 45px;

      &.left {
        left: 30px;
      }

      &.right {
        right: 30px;
      }
    }

    &_circle {
      display: inline-block;
      margin-bottom: 10px;
      padding: 0;
      width: 45px;
      height: 45px;
      line-height: 47px;
      border-radius: 50%;
      border: none;
      background-color: #fff;
      font-size: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
      text-align: center;
      margin-right: 10px;
      cursor: pointer;

      @include transition(all 0.2s);

      &:hover {
        background-color: $light;
      }

      svg {
        height: 20px;
        width: 20px;
      }

      &.red {
        svg {
          path {
            fill: $red;
          }
        }
      }

      &.blue {
        svg {
          path {
            fill: $blue;
          }
        }
      }
    }
  }

  &_title {
    background-color: #fff;
    padding-top: 10px;

    // padding-bottom: 30px;
    h1 {
      // font-size: 2.625rem;
      font-size: 1.5rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom: 0;
    }

    &_back {
      h1 > a {
        display: inline-block;
        padding-left: 30px;
        position: relative;

        &:before {
          content: '';
          margin-right: 12px;
          display: inline-block;
          position: absolute;
          top: 15px;
          left: 0;
          width: 18px;
          height: 18px;
          border-bottom: 4px solid $blue;
          border-left: 4px solid $blue;
          @include transition(all 0.2s);
          @include transform(rotate(45deg));
        }
      }
    }
  }

  &_price {
    padding-top: 30px;
    padding-bottom: 30px;

    &_box {
      position: relative;
      background-color: #777;
      color: #fff;
      font-size: 1rem;
      font-weight: bold;
      min-width: 100px;
      border-radius: 5px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      display: inline-block;
      padding-left: 15px;
      padding-right: 15px;
      text-transform: uppercase;

      &.red {
        background-color: $red;
      }

      &.green {
        background-color: $green;
      }

      &.blue {
        background-color: $blue;
      }
    }

    &_box + &_box {
    }
  }

  &_user {
    background-color: #fff;
    padding-top: 30px;
    padding-bottom: 30px;

    &_profile {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: start;
      -ms-flex-align: start;
      align-items: flex-start;

      & > img {
        vertical-align: middle;
        margin-right: 15px;
        align-self: center;
        height: 70px;
      }

      & > div {
        -webkit-box-flex: 1;
        -ms-flex: 1;
        flex: 1;
        min-width: 10px;
        align-self: center;
      }

      h5 {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 5px;
      }
    }

    &_icons {
      display: inline-block;
      border-radius: 4px;
      background-color: $light;
      padding: 5px 8px;

      span {
        display: inline-block;
      }

      span + span {
        margin-left: 6px;
      }
    }

    &_percentage {
      color: $green;
      font-size: 24px;
      font-weight: bold;
      line-height: 30px;
      display: inline-block;

      img {
        position: relative;
        top: -2px;
        vertical-align: middle;
        margin-left: 3px;
        height: 30px;
      }
    }
  }

  &_info {
    padding-top: 30px;
    padding-bottom: 10px;

    &_label {
      display: block;
      font-size: 16px;
      margin-bottom: 5px;
      font-weight: 400;
    }

    &_box {
      background-color: #fff;
      padding: 10px;
      margin-bottom: 20px;
      font-weight: 500;
      font-size: 17px;
    }
  }

  &_date {
    background-color: rgba(65, 117, 223, 1);
    background: linear-gradient(180deg, rgba(65, 117, 223, 1) 0%, rgba(33, 78, 170, 1) 100%);
    padding-top: 20px;
    padding-bottom: 20px;

    &_box {
      background-color: #fff;
      padding: 10px 20px;
      margin-bottom: 3px;

      b {
        font-weight: 600;
        display: block;
        font-size: 15px;
      }

      span {
        font-size: 16px;
        display: block;
        font-weight: 400;
        color: #888;
      }
    }
  }

  &_features {
    padding-top: 30px;
    padding-bottom: 10px;

    dl {
      margin: 0 0 20px;
      font-size: 17px;

      dt,
      dd {
        background-color: #fff;
        margin-bottom: 10px;
        padding: 10px 15px;
      }

      dt {
        font-weight: 400;
      }

      dd {
        text-align: right;
      }
    }
  }

  &_short {
    padding-top: 30px;
    padding-bottom: 30px;

    p {
      font-size: 20px;
      color: #555;
    }

    .button {
      margin-top: 15px;
      width: 100%;
    }
  }
}

@media only screen and (min-width: 1300px) {
  .detail_slider {
    .slick-next {
      right: -80px;
    }

    .slick-prev {
      left: -80px;
    }
  }
}

@include lg {
  .detail {
    &_slider {
      &_item {
        height: 527px;

        img {
          max-height: 320px;
        }
      }

      .slick-slide {
        max-height: 320px;
      }
    }
  }
}

@include md {
  .detail {
    &_slider {
      &_item {
        height: 391px;

        img {
          max-height: 391px;
        }
      }

      .slick-slide {
        max-height: 391px;
      }

      .slick-next,
      .slick-prev {
        display: none !important;
      }
    }
  }
}

@include sm {
  .detail {
    &_slider {
      & > .container {
        padding-left: 0;
        padding-right: 0;
      }

      &_item {
        height: 205px;

        img {
          max-height: 205px;
        }
      }

      .slick-slide {
        max-height: 205px;
      }

      &_circles {
        width: 32px;

        &.left {
          left: 15px;
          top: 15px;
        }

        &.right {
          right: 15px;
          top: 15px;
        }
      }

      &_circle {
        width: 32px;
        height: 32px;
        line-height: 32px;
        margin-bottom: 7px;
        text-align: center;

        svg {
          height: 15px;
          width: 15px;
        }
      }

      &_number {
        right: 15px;
        bottom: 15px;
      }
    }

    &_title {
      padding-top: 20px;
      padding-bottom: 20px;

      h1 {
        font-size: 22px;
      }

      &_back {
        h1 > a {
          padding-left: 20px;

          &:before {
            top: 7px;
            width: 12px;
            height: 12px;
            border-left-width: 3px;
            border-bottom-width: 3px;
          }
        }
      }
    }

    &_price {
      padding-top: 20px;
      padding-bottom: 20px;

      &_box {
        font-size: 15px;
        min-width: 50px;
        padding-left: 10px;
        padding-right: 10px;
      }

      &_box + &_box {
        margin-left: 0px !important;
      }
    }

    &_user {
      padding-top: 20px;
      padding-bottom: 20px;

      &_profile {
        display: block;

        & > img {
          height: 50px;
          margin-right: 10px;
        }

        h5 {
          font-size: 16px;
        }
      }

      &_percentage {
        font-size: 20px;

        img {
          height: 20px;
        }
      }
    }

    &_info {
      padding-bottom: 0;
      padding-top: 20px;

      &_label {
        font-size: 15px;
      }

      &_box {
        font-size: 15px;
      }
    }

    &_date {
      padding-top: 20px;
      padding-bottom: 10px;

      &_box {
        padding: 13px;

        span {
          font-size: 13px;
        }
      }
    }

    &_features {
      dl {
        font-size: 15px;

        dt,
        dd {
          padding: 8px 13px;
        }
      }
    }

    &_short {
      padding-top: 10px;
      padding-bottom: 20px;

      p {
        font-size: 17px;
      }

      .button {
        margin-top: 10px;
      }
    }
  }
}

.detail_slider_circle.svg_edit.button {
  display: flex;
  align-items: center;
  width: fit-content;

  @media screen and (max-width: 580px) {
    display: flex;
    flex-direction: row;
    text-align: center;
    justify-content: center;
    font-size: 1.1rem;
  }
}

.img-fluid.no-image {
  width: 143px !important;
  object-fit: contain;
  height: 116px !important;
}

.post-credit {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  font-size: 11px;
  background-color: #ffffff !important;
  color: #e51a1a;
  border: 1px solid #e51a1a;
  white-space: pre;
  margin-right: 0 !important;
}

.red {
  background-color: #e51a1a !important;
  color: #fff !important;
}

@media screen and (max-width: 580px) {
  .slider.animated {
    max-height: 372px;
  }

  .detail_boxes img {
    margin: 0 0.75rem;
    background: #fff;
    border-radius: 50%;
  }

  .detail .img {
    padding: 0 !important;
    margin: 0 !important;
  }

  .edit-holder {
    text-align: center;
  }
}

.detail_price_box {
  height: auto;
  line-height: normal;
  margin-right: 5px;
  padding: 5px;
  min-width: auto;
  margin-bottom: 10px;
}

.detail_price {
  padding-top: 10px;
  padding-bottom: 0;
}

.pointer {
  cursor: pointer;
}

.telephone_icon {
  display: inline-block;

  width: 100%;
  height: 30px;

  text-align: center;
}

.tel_icon {
  display: inline-block;

  width: 30px;
  height: 30px;

  object-fit: contain;
}

.p0 {
  padding: 0px !important;
}

.inline_divs {
  display: inline-block;
  width: 100%;
}

.inline_divs img,
.inline_divs span {
  display: inline-block;
}

.mr5 {
  margin-right: 5px;
}

.txt-left {
  text-align: left !important;
}

.user_name_text {
  display: inline-block;
  max-width: 368px;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  overflow: hidden;
}

@media screen and (max-width: 768px) {
  .user_name_text {
    max-width: 125px;
  }
}

@media screen and (max-width: 375px) {
  .detail_date_box {
    padding: 10px;

    span {
      font-size: 11px;
    }

    b {
      font-size: 13px;
    }
  }
}

.detail_slider_wrap[sold] img {
  opacity: 0.6;
}

.detail_price_box[sold] {
  background-color: #e51a1a;
}

.button.finish-sale {
  font-size: 1.1rem;
  font-weight: 900;
  min-width: max-content;
}

.detail_features > .container + .container {
  @media screen and (max-width: 500px) {
    padding-bottom: 2rem;
  }
}
